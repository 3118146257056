body {
    font-family: 'Karla', sans-serif;
}
body p { text-align: justify;}
 .navbar {
    background: #e6ebec;
    border: 1px solid #f1f1f1;
    margin-bottom: 0;
       padding: 0px;

}
.navbar-brand .img-fluid {
    max-width: 80%;
    height: auto;
}
.topBar {
    background-color: #008ae7;
    color: #fff;
    padding: 5px 0px;
}
.navbar-nav .active{
     color: #008ae7!important;
 
   }
a.nav-link {
    font-weight: 600;
    color: #008ae7;
}

.navbar-nav span {
    font-size: 13px;
    font-weight: 600;
    color: #213ca8;
}
.navbar-nav a {
    display: block;
    color: #000000;
    font-weight: 600;
    padding: 20px 10px;
    position: relative;
    font-size: 16px;     margin-right: 15px;

}
.cardSection { background-color: #f1f1f1;}
.container h1 { color: #003075; text-align: center;}
.footerSection { background-color: #fff;}
.footerSection h5{ color: #213da9 !important; font-size:25px;    font-weight: 700;}
.Cardtitle { text-transform: uppercase; color: #003075;   text-align: center;}
.form-label {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}
.container p a {
    color:  #003075 !important;
    font-weight: 600;
     padding: 3px;
}
.grayBg { background-color:#f4f5f9 ;}
.grayBg .table  { background-color:#fff ;}

.bookingform { background-color: #f1f1f1; padding: 0px 15px;   border-radius:10px;}
.AboutBg { position: relative ;}
.AboutBg h1{ position: relative ; color: #fff;}
.aboutSection p { color: #fff;font-size: 17px;
    line-height: 28px; }
.AboutBg:before { position: absolute ; content:""; left: 0; right: 0; width: 100%; height: 100%;background-color: rgb(0 0 0 / 70%);  }
.footerSection .container p a {
    color:  #213da9 !important;
    font-weight: 600;
     padding: 3px;
}
.footerSection .container p , .footerSection li a , .footerSection li a.active{ color: #213da9 !important;} 
body p {
    font-size: 15px;
}
a:hover , a{
    text-decoration-line: none;
}
.contactUS .fa {
    color: #003075;
    font-size: 17px;
    margin-right: 7px;
}
h3.py-3, h4.pt-3 {
    color:  #003075;
}
thead {
    background-color: #1f41af;
    color: #fff;
}
tbody {
    background-color: #fbfbfb;
}
.blackBg {    background-color: #f1f1f1 !important; color: #000;
}
.BabjiproductDiv {
    border: 2px solid #607ea8;
    position: relative;
    margin-bottom: 60px;
    min-height: 200px;  -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.downloadSection{ display: flex ;align-items: center;}
.downloadSection a { color: #fff; text-transform: capitalize;    margin: auto;
    ;}
.BabjiproductDiv p { text-align: center;  position: absolute;
    right:0px;
    left: 0px; bottom: 0; color: #151716;font-size: 17px; -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;}
.BabjiproductDiv img {
    position: absolute;
    top: -60px;
     left: 0px;
    right: 0px;
    margin: auto;
}
.shipSection { background-color: #f4f5f9;}
.productsNameDiv ul li ,.productsNameDiv ul li a{ list-style-type: square; color: #fff; font-size: 15px;}
.productsNameDiv p  { text-align: center;}
.productsNameDiv .col-lg-6{position: relative; }
.productsNameDiv .col-lg-6:first-child { border-right: 2px solid #3f6397; }
a#basic-nav-dropdown { text-align: left; padding: 10px 10px!important;} 
 .nav-tabs  {
    margin-bottom: 3.4rem;
}
.nav-tabs button {
    font-size: 16px;
    font-weight: 600;
}
.float .fa , .float_left .fa { background-color: transparent; color:#fff;}
.float {
    background-color: #25d366;
    border-radius: 50px;
    bottom: 105px;
    box-shadow: 2px 2px 3px #999;
    color: #fff;
    font-size: 30px;
    height: 60px;
    line-height: 60px;
    position: fixed;
    right: 19px;
    text-align: center;
    width: 60px;
    z-index: 100;
}


.float_left  {
    background-color: #f501a1;
    border-radius: 50px;
    bottom: 25px;
    box-shadow: 2px 2px 3px #999;
    color: #fff;
    font-size: 30px;
    height: 60px;
    line-height: 60px;
    position: fixed;
    right: 19px;
    text-align: center;
    width: 60px;
    z-index: 100;
}
.my-float {
    margin-top: 16px;
}
.footerSection .insta .fa {   background-color: #fff; color:#008ae7 ; width: 45px; height: 45px;line-height: 45px; text-align: center; border-radius: 50%; -webkit-transition: .5s;  -o-transition: .5s;  transition: .5s;     font-size: 30px;
}
.footerSection .insta .fa:hover{background-color:#008ae7 ; color:#fff ;-webkit-transition: .5s;  -o-transition: .5s;  transition: .5s;}

.BabjiproductDiv:hover {     border: 2px solid #008ae7;   -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.BabjiproductDiv:hover.BabjiproductDiv p {
    color:  #008ae7;-webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;}
    
    .productDiv { min-height: 250px; background-size: cover; background-position: center; background-repeat: no-repeat; border-radius: 10px; position: relative; margin-bottom: 25px;     overflow: hidden;
}
.waterImages img{ padding:45px; }
 .productDiv .captionDiv {
     color: #0098a3;
    padding: 20px 10px;
    bottom: 0;
    position: absolute;
    width: 90%;
      top: 143px; 
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 21px;
    text-transform: capitalize;
    font-weight: 500;
    opacity: 1; padding: 15px 30px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.owl-carousel .productDiv .captionDiv {
     width: 100%;
    top: 201px;}

    .justify-content-center .productDiv .captionDiv {
        width: 90%;
       top: 201px;}
.productDiv .captionDiv .plus {     position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px); }


  /*  .productDiv .captionDiv {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    padding: 15px 30px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    overflow: hidden;
    width: 90%;
}
*/

.productDiv .captionDiv h3 {
    font-size: 16px;
    font-weight: 700;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    color: #fff;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
}
.productDiv:hover .captionDiv h3 {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}
.productDiv:hover .captionDiv:before {
     opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }

.productDiv .captionDiv:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:rgb(25 73 13 / 70%);
    opacity: 1;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
   /* -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);*/
}
.borderTop {
    border-top: 1px dotted #919497;
    margin: 15px 0px;
}
.contactUS h5 { text-transform: uppercase;}
.contactUS p { font-weight: 500;     font-size: 14px;
}
.contactUS p b {   color: #003075 !important;
    font-size: 14px;
    text-transform: uppercase;

}
.copyright { background-color: #1f41af;
    color: #fff;
}
.copyright p { margin-bottom: 0px;}
.contactUS p a {
    color: #003075 !important;
    font-weight: 500;
    padding: 3px;
    font-size: 14px;
}
.productDiv .captionDiv .plus{    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
        opacity: 0;
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
}

.productDiv:hover .captionDiv {
    opacity: 1;
}
.productDiv:hover .plus {    
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px); opacity: 1; }

.productDiv .captionDiv .plus a {
    background-image: -webkit-linear-gradient( 0deg,  rgb(51 129 32 / 70%)  0%, rgb(25 73 13 / 50%)100%);
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
}
 /* ===================== */
 .owl-theme .owl-nav .owl-left , .owl-theme .owl-nav .owl-next{ left:0; position: relative; transition: all 0.3s ease-in-out;}
 .owl-theme .owl-nav [class*='owl-']:hover {
    background: transparent;
    color: #FFF;
    text-decoration: none;
}
 .owl-theme .owl-nav .owl-left:hover{  right: 10px; position: relative; transition: all 0.3s ease-in-out;}
.owl-theme .owl-nav .owl-next:hover {  left: 10px;  position: relative;transition: all 0.3s ease-in-out;
}


@media (min-width: 767px) and (max-width:991px){
    .shipSection .col-lg-3 {
        margin: auto;
        display: block;
        text-align: center;
    }
    .aboutText { text-align: center; color: #fff;}

    .shipSection .col-lg-3 h4 p {
        text-align: center;
        margin-bottom: 20px;
    }
    
}
@media (max-width: 480px){
    .topBar span {
        font-size: 13px!important;
    }
}
@media (max-width: 767px){
    .navbar-nav span{ font-size: 14px;}
    .navbar-brand .img-fluid {
        max-width: 50%;
        height: auto;
    }
    span.navbar-brand {
        width: 270px;
    }
    .aboutSection p{ font-size: 15px;
        line-height: 25px;
    }
    .topBar span {
        font-size: 14px;
    }
    .shipSection .col-lg-3 {margin: auto;
        display: block;
        text-align: center; }
        .shipSection .col-lg-3 h4 { text-align: center; margin-top: 15px;     font-size: 35px;
  font-weight: 600;  color:#003075;       }
        .shipSection .col-lg-3 h4 p { text-align: center; margin-bottom: 20px;; }
    .breadcrumb-area {     min-height: 142px!important ;    }
    .ProductsSection  img { margin: 20px auto; display: block;}
    .navbar-nav a  { padding: 6px 10px ;  }
    .aboutSection h2 { color: #fff; font-size: 25px; text-align: center;}
    .aboutSection { background-color: #003075; padding: 20px 0px;}
    .py-5 {
        padding-top: 15px!important;
        padding-bottom: 15px!important;
    }
}

@media (min-width: 992px){
.navbar-expand-lg .navbar-collapse {
    display: grid!important;
    flex-basis: auto;
}
span.navbar-brand {
    margin-right: -151px;
}
.navbar-nav > a:first-child, .navbar-nav span {
    margin-left: -15%;
}
.dropdown-menu a { margin-left: 0; border-top: none!important;  width: auto!important;  padding: 5px 4px!important;}
.aboutSection { position: relative;  background-size: cover;  background-repeat: no-repeat;  background-position: 270px 0px;
}.aboutSection img { z-index: 10;  position: relative;     
}

/* .aboutSection::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-color: transparent;
    border-style: solid;
}

.aboutSection::before {
    border-width: 212px;
    border-right-color: #003075;
    border-top-color: #003075;
    border-left-color: #fff;
    border-bottom-color: #fff;

} */


.navbar-nav a {
    display: block;
    color: #000000;
    font-weight: 600;
    padding: 20px 10px;
    position: relative;
    font-size: 16px;
    border-top: 2px solid #d1d3d4;
    margin-right: 0px;
    width: 100px;
    text-align: center;
 
}

aside h3 {
    font: bold 12px Sans-Serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #369;
    color: #fff;
    padding: 5px 10px;
    margin: 0 0 10px 0;
    line-height: 24px;
  }
  
  /* Class name via Modernizr */
   aside {
    border-left: 34px solid #369;
    padding-left: 10px;
    position: relative;
  }
   aside h3 {
    /* Abs positioning makes it not take up vert space */
    position: absolute;
    top: 0;
    left: 0;
  
    /* Border is the new background */
    background: none;
  
    /* Rotate from top left corner (not default) */
    transform-origin: 0 0;
    transform: rotate(90deg);
  }

    .art-side-form-side-text {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 0;
    width: 0;
    white-space: nowrap;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    text-align: left;
    position: absolute;
    padding-bottom: 1rem;
    font-size: 25px;
     font-weight: 600;
    letter-spacing: 0.1em;
    color: #fff;
}

.art-side-form-side-text:after {
    border-bottom: 1px solid #3f6397;
    color: #fff;
    position: absolute;
    content: "";
    height: 0px;
    width: 12rem;
    left: 3px;
    bottom: 0px;
    top: 25px;
    margin-left: 125px;
}

.art-side-form-side-text2 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    margin: 0;
    width: 0;
    white-space: nowrap;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    text-align: left;
    position: absolute;
    padding-bottom: 1rem;
    font-size: 25px;
     font-weight: 600;
    letter-spacing: 0.1em;
    color: #003075;left: 50%;
    top: 50%;
}
.productSection .col-lg-1 { position: relative;}

    .ProductsSection .col-lg-2{ text-align: left;}
    .ProductsSection .col-lg-2 li{ text-align: left;}
    .secondHeading { color: #003075;     display: table;

        border-bottom: 1px solid #0098a3;
        text-align: center;
         margin: auto;
        text-align: center;     font-size: 22px;
    }

.art-side-form-side-text2:after {
    border-bottom: 1px solid #3f6397;
    color: #003075;
    position: absolute;
    content: "";
    height: 0px;
    width: 12rem;
     bottom: 0px;
    top: 35px;
    margin-right: 125px;
}
.shipSection .col-lg-3 { display: flex; align-items: center;}
.shipSection img { max-width: 40%!important; margin-right: 15px;  }
.shipSection h4 {color:#003075; font-size: 30px; font-weight: 700; margin-bottom: 10px;}
.shipSection h4 p { font-size: 16px; color: #009ee7;     margin-bottom: 0px;
    
    font-weight: 500;
}
.navbar-nav .active{
    color: #008ae7!important;
   border-top: 2px solid #008ae7;    

  }


}


/* =====================================Subheadr ===================================*/
section.breadcrumb-area :hover .breadcrumb-area { transform: scale(1.1);  -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;  }
    .breadcrumb-area {     background-size: 100%;     position: relative;     background-repeat: no-repeat;
        margin-top: 0px;    display: flex;    align-items: center;    transform: scale(1);  background-position: center;
        min-height: 300px;    }

  /* .breadcrumb-area:before {
  background-color: hwb(245deg 2% 71% / 70%); position: absolute; content: ''; left: 0; top: 0; width: 100%;height: 100%; } */
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: #01a5fe;
    color: #fff!important;
}
.dropdown-item:active .navbar-nav a , .dropdown-item:hover .navbar-nav a {     color: #fff!important;
    background-color: #01a5fe;
}
  .breadcrumb {
  display: inline-block;
  -ms-flex-wrap: wrap;
  flex-wrap: unset;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: unset;
  border-radius: 0;
  
  }
  .breadcrumb li {display: inline-block;}
  .breadcrumb li a {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  }
  .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 15px;
  padding-left: 10px;
  color: #ddd;
  content: "|";
  }
  .breadcrumb-title h2 {
  font-size: 48px;
  margin-bottom: 25px;
  line-height: 1;
  color: #fff;
  }
  .breadcrumb-title p {
  margin-bottom: 0;
  color: #777;
  font-size: 16px;
  }
  .breadcrumb > .active {
    color: #ffffff !important;
    font-weight: 500;
  }
  .objectives li {
   position: relative;
    color: #000;
    font-size: 15px;
    padding: 14px 0px;
    padding-left: 15px;
  }
  
  .breadcrumb-wrap:first-child {
    display: block;
      padding: 20px;
      color: #fff;
      position: relative;
      text-align: center;
  }
  .objectives li:before { 
   position: absolute;
    content: "";
    width: 0;
    left: -12px;
    height: 0;
    top: 18px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #4fae57; }
  
    /*--------------------------------*/


    @media (min-width: 768px) {
        .animate {
          animation-duration: 0.3s;
          -webkit-animation-duration: 0.3s;
          animation-fill-mode: both;
          -webkit-animation-fill-mode: both;
        }
      }
      
      @keyframes slideIn {
        0% {
          transform: translateY(1rem);
          opacity: 0;
        }
      
        100% {
          transform: translateY(0rem);
          opacity: 1;
        }
      
        0% {
          transform: translateY(1rem);
          opacity: 0;
        }
      }
      
      @-webkit-keyframes slideIn {
        0% {
          -webkit-transform: transform;
          -webkit-opacity: 0;
        }
      
        100% {
          -webkit-transform: translateY(0);
          -webkit-opacity: 1;
        }
      
        0% {
          -webkit-transform: translateY(1rem);
          -webkit-opacity: 0;
        }
      }
      
      .slideIn {
        -webkit-animation-name: slideIn;
        animation-name: slideIn;
      }
      .contactUS img { margin: auto; max-width: 10%;}
      .contactUS p {
        margin-bottom: 5px;
    }
      button.btn.btn-primary, button.btn.btn-primary:focus , button.btn.btn-primary:hover {
        background: #2337a3;
        border-color: #2337a3;
    }
      /* Other styles for the page not related to the animated dropdown */
      
      /*Animation*/

      @keyframes rocking {
  0% {
      transform: scale(1);
  }
  25% {
      transform: scale(1.1);
  }
  50% {
      transform: scale(1);
  }
  75% {
      transform: scale(1.1);
  }
  100% {
      transform: scale(1);
  }
}
@-webkit-keyframes Gradient {
  0% {
      background-position: 0 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}
@-moz-keyframes Gradient {
  0% {
      background-position: 0 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}
@keyframes Gradient {
  0% {
      background-position: 0 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}
 .effetMoveGradient, .effetMoveGradient:hover {
    /* text-shadow: 0 3px 6px #00003e;
  background-color:  #629ea1;
  background: linear-gradient(-45deg,  #00003e, #ee402f, #00003e,#629ea1); */

  background-size: 400% 400%; 
     -webkit-animation: Gradient 3s ease infinite,rocking 3s ease infinite;
    animation-delay: 2s;
    -moz-animation: Gradient 3s ease infinite,rocking 3s ease infinite;
    animation-delay: 2s;
    animation: Gradient 3s ease infinite,rocking 3s ease infinite;
    animation-delay: 2s;
  } 
